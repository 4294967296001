import { SvgIcon } from "@material-ui/core";

function LogoIcon(props) {

    return (
        <SvgIcon {...props}>
            <defs>
                <radialGradient id="radialGradient6050" cx="41.934" cy="152.59" r="7.6894" gradientTransform="matrix(2.7081 0 0 3.8863 -71.629 -440.43)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f00" offset="0" />
                    <stop stopColor="#f00" stopOpacity="0" offset="1" />
                </radialGradient>
            </defs>
            <g transform="translate(-34.245 -141.6)">
                <path d="m41.934 141.6c-4.2512 0-7.6894 3.4383-7.6894 7.6895 0 5.7671 7.6894 14.28 7.6894 14.28s7.6894-8.5133 7.6894-14.28c0-4.2512-3.4383-7.6895-7.6894-7.6895z" fill="url(#radialGradient6050)" />
                <path d="m44.742 147.13 0.0038-4e-3 -1.389-1.389-0.3958 0.3958 0.78786 0.78786c-0.35099 0.13436-0.60117 0.47047-0.60117 0.87001 0 0.51528 0.4182 0.93348 0.93349 0.93348 0.13442 0 0.25764-0.0299 0.37339-0.0785v2.6922c0 0.20537-0.16803 0.37339-0.37339 0.37339-0.20537 0-0.3734-0.16802-0.3734-0.37339v-1.6803c0-0.41073-0.33606-0.74678-0.74679-0.74678h-0.3734v-2.6138c0-0.41073-0.33606-0.74678-0.74679-0.74678h-2.2404c-0.41074 0-0.74679 0.33605-0.74679 0.74678v5.9743h3.7339v-2.8005h0.56009v1.867c0 0.51528 0.4182 0.93349 0.93349 0.93349 0.51528 0 0.93349-0.41821 0.93349-0.93349v-3.5472c0-0.25766-0.10455-0.49288-0.27258-0.66092zm-2.9013 1.0343h-2.2404v-1.867h2.2404zm2.2404 0c-0.20537 0-0.3734-0.16803-0.3734-0.37339 0-0.20538 0.16803-0.37341 0.3734-0.37341s0.37339 0.16803 0.37339 0.37341c0 0.20536-0.16803 0.37339-0.37339 0.37339z" fill="#fff" strokeWidth=".37339" />
            </g>
        </SvgIcon>
    );
}

function LogoIconBlack(props) {

    return (
        <SvgIcon {...props}>
            <g transform="translate(-34.245 -141.6)">
                <path d="m41.934 141.6c-4.2512 0-7.6894 3.4383-7.6894 7.6895 0 5.7671 7.6894 14.28 7.6894 14.28s7.6894-8.5133 7.6894-14.28c0-4.2512-3.4383-7.6895-7.6894-7.6895z" />
                <path d="m44.742 147.13 0.0038-4e-3 -1.389-1.389-0.3958 0.3958 0.78786 0.78786c-0.35099 0.13436-0.60117 0.47047-0.60117 0.87001 0 0.51528 0.4182 0.93348 0.93349 0.93348 0.13442 0 0.25764-0.0299 0.37339-0.0785v2.6922c0 0.20537-0.16803 0.37339-0.37339 0.37339-0.20537 0-0.3734-0.16802-0.3734-0.37339v-1.6803c0-0.41073-0.33606-0.74678-0.74679-0.74678h-0.3734v-2.6138c0-0.41073-0.33606-0.74678-0.74679-0.74678h-2.2404c-0.41074 0-0.74679 0.33605-0.74679 0.74678v5.9743h3.7339v-2.8005h0.56009v1.867c0 0.51528 0.4182 0.93349 0.93349 0.93349 0.51528 0 0.93349-0.41821 0.93349-0.93349v-3.5472c0-0.25766-0.10455-0.49288-0.27258-0.66092zm-2.9013 1.0343h-2.2404v-1.867h2.2404zm2.2404 0c-0.20537 0-0.3734-0.16803-0.3734-0.37339 0-0.20538 0.16803-0.37341 0.3734-0.37341s0.37339 0.16803 0.37339 0.37341c0 0.20536-0.16803 0.37339-0.37339 0.37339z" fill="#fff" strokeWidth=".37339" />
            </g>
        </SvgIcon>
    );
}

export { LogoIcon, LogoIconBlack };